import React from 'react';

import { CMSLink } from 'components/cmsLink';
import CognizantSoftvisionLogo from 'icons/cognizant-softvision-logo.svg';

const Header = () => {
  return (
    <div className="hackathon-header">
      <CMSLink
        linkUrl="https://cognizantsoftvisionevents.com/ourdnalithuaniapoland"
        className="hackathon-header__link"
      >
        <CognizantSoftvisionLogo className="hackathon-header__logo" />
      </CMSLink>
    </div>
  );
};

export { Header };

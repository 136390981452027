import React, { useContext, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './scss/styles.scss';
import { GlobalStateContext } from 'containers/GlobalContextProvider';
import { FaqCard } from './FaqCard';

const Faq = ({
  title,
  questions,
  className,
  otherQuestions,
  questionsEmail,
}) => {
  const languageContext = useContext(GlobalStateContext);
  const translation = languageContext.dictionary.translations;

  const faqRef = useRef(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(null);

  const handleKeyDown = (e) => {
    const getCardButton = (upcomingCardIndex) =>
      faqRef.current.children[upcomingCardIndex].children[0];
    const lastCardButton = faqRef.current.lastChild.children[0];
    const firstCardButton = faqRef.current.firstChild.children[0];

    // eslint-disable-next-line default-case
    switch (e.key) {
      case 'ArrowDown':
        if (faqRef.current) {
          e.preventDefault();
          if (
            currentQuestionIndex < questions.length - 1 &&
            getCardButton(currentQuestionIndex + 1)
          ) {
            getCardButton(currentQuestionIndex + 1).focus();
          } else {
            if (firstCardButton) {
              firstCardButton.focus();
            }
          }
        }
        break;
      case 'ArrowUp':
        if (faqRef.current) {
          e.preventDefault();
          if (
            currentQuestionIndex > 0 &&
            getCardButton(currentQuestionIndex - 1)
          ) {
            getCardButton(currentQuestionIndex - 1).focus();
          } else {
            if (lastCardButton) {
              lastCardButton.focus();
            }
          }
        }
        break;
      case 'Home':
        if (faqRef.current) {
          e.preventDefault();
          if (firstCardButton) {
            firstCardButton.focus();
          }
        }
        break;
      case 'End':
        if (faqRef.current) {
          e.preventDefault();
          if (lastCardButton) {
            lastCardButton.focus();
          }
        }
        break;
    }
  };

  const renderOtherQuestions = () =>
    otherQuestions && (
      <p className="faq__other-questions">
        {`${otherQuestions} `}
        {questionsEmail && (
          <a href={`mailto:${questionsEmail}`} className="faq__link">
            {`${translation['faq.otherQuestions.text']}.`}
          </a>
        )}
      </p>
    );

  return (
    questions &&
    questions.length > 0 && (
      <div className={classNames('faq', className && `${className}`)}>
        <div className="faq__frame">
          <h2 className="faq__title">{title}</h2>
          <div
            aria-label={translation['accordion.groupButtons']}
            id="faqAccordionGroup"
            className="faq__accordion"
            ref={faqRef}
          >
            {questions.map((question, index) => (
              <FaqCard
                key={question.questionText}
                index={index}
                answer={question.answer}
                question={question.questionText}
                setCurrentQuestionIndex={setCurrentQuestionIndex}
                handleKeyDown={handleKeyDown}
              />
            ))}
          </div>
          {renderOtherQuestions()}
        </div>
      </div>
    )
  );
};

Faq.propTypes = {
  title: PropTypes.string.isRequired,
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      answer: PropTypes.string.isRequired,
      questionText: PropTypes.string.isRequired,
    })
  ).isRequired,
  className: PropTypes.string,
  otherQuestions: PropTypes.string,
  questionsEmail: PropTypes.string,
};

export { Faq };

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import generateUniqueId from 'utilities/generateUniqueId';
import Img from 'gatsby-image';

const Image = ({ side, text, image }) => {
  return (
    <div
      className={classNames(
        'hackathon-agenda__image-wrapper',
        `hackathon-agenda__image-wrapper--${side}`
      )}
    >
      <Img fluid={image} alt={text} className="hackathon-agenda__image" />
    </div>
  );
};

Image.propTypes = {
  side: PropTypes.string,
  text: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.object]),
};

const Agenda = ({ title, morningEvents, afternoonEvents }) => (
  <div className="hackathon-agenda">
    <h2 className="hackathon-agenda__title">{title}</h2>
    <div className="hackathon-agenda__wrapper">
      <div className="hackathon-agenda__column">
        {morningEvents && morningEvents.length > 0 && (
          <ul className="hackathon-agenda__list">
            {morningEvents.map((event) => (
              <li
                className="hackathon-agenda__list-item"
                key={generateUniqueId()}
              >
                <p className="hackathon-agenda__time">{event.time}</p>
                <p className="hackathon-agenda__event">{event.event}</p>
              </li>
            ))}
          </ul>
        )}
      </div>
      <div className="hackathon-agenda__column">
        {afternoonEvents && afternoonEvents.length > 0 && (
          <ul className="hackathon-agenda__list hackathon-agenda__list--dark">
            {afternoonEvents.map((event) => (
              <li
                className="hackathon-agenda__list-item"
                key={generateUniqueId()}
              >
                <p className="hackathon-agenda__time">{event.time}</p>
                <p className="hackathon-agenda__event">{event.event}</p>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  </div>
);

Agenda.propTypes = {
  title: PropTypes.string,
  morningEvents: PropTypes.arrayOf(
    PropTypes.shape({
      time: PropTypes.string,
      event: PropTypes.string,
    })
  ),
  afternoonEvents: PropTypes.arrayOf(
    PropTypes.shape({
      time: PropTypes.string,
      event: PropTypes.string,
    })
  ),
};

export { Agenda };

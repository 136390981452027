import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const CMSLink = ({ children, className, linkUrl }) => {
  const isExternal = /^https?:/.test(linkUrl);
  const hasSourceryDomain = /^(https?:\/\/(www.)?sourceryacademy.com).*$/.test(
    linkUrl
  );

  if (isExternal && !hasSourceryDomain) {
    return (
      <a
        className={className}
        href={linkUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        {children}
      </a>
    );
  }

  return (
    <Link
      className={className}
      to={
        hasSourceryDomain
          ? linkUrl.replace(/^(https?:\/\/(www.)?sourceryacademy.com)/, '')
          : linkUrl
      }
    >
      {children}
    </Link>
  );
};

CMSLink.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string.isRequired,
  linkUrl: PropTypes.string.isRequired,
};

export { CMSLink };

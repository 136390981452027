import scrollTo from 'gatsby-plugin-smoothscroll';

const handleScrollToForm = (formFieldRef, elementId) => {
  const focusFormField = () => {
    if (
      window.innerHeight + window.scrollY >= document.body.offsetHeight &&
      formFieldRef
    ) {
      formFieldRef.current.focus();
      window.removeEventListener('scroll', focusFormField);
    }
  };
  scrollTo(elementId);
  window.addEventListener('scroll', focusFormField);
};

export { handleScrollToForm };

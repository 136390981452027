import React from 'react';
import PropTypes from 'prop-types';

import Pixel from 'images/hackathon-pixel-white.png';

const HeroBanner = ({ title, subTitle, date, location }) => (
  <div className="hackathon-hero-banner">
    <div className="hackathon-hero-banner__content-wrapper">
      <div className="hackathon-hero-banner__content">
        <div className="hackathon-hero-banner__text">
          <div className="hackathon-hero-banner__info hackathon-hero-banner__info--info">
            Registration
          </div>
          <div className="hackathon-hero-banner__info">
            <p className="hackathon-hero-banner__date">
              {date}
              <span className="hackathon-hero-banner__info-separator">|</span>
            </p>
            <p className="hackathon-hero-location">{location}</p>
          </div>
          <h1 className="hackathon-hero-banner__title">
            {title}
            <span className="hackathon-hero-banner__sub-title">{subTitle}</span>
          </h1>
        </div>
        <span className="btn">Registration has ended</span>
      </div>
      <figure className="hackathon-hero-banner__figure">
        <img
          src={Pixel}
          alt="Robot hand"
          className="hackathon-hero-banner__image"
        />
      </figure>
    </div>
  </div>
);

HeroBanner.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
};

export { HeroBanner };

import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import classNames from 'classnames';
import { languageOptions } from 'lang';

import { isWhiteSpace } from 'utilities';
import { GlobalStateContext } from 'containers/GlobalContextProvider';
import ConfirmationIcon from 'images/confirmation-icon.svg';
import { LoadingIndicator } from 'components/loadingIndicator';
import {
  FormField,
  FormTextArea,
  SubmitButton,
  SubmitMessage,
} from 'components/forms';
import 'components/forms/scss/form.scss';
import { FormSelect } from '../../../components/forms';

const requiredFieldText = 'Field is required.';
const maxFieldText = 'Field value is too long.';
const maxSymbolCount = 5000;

const validationSchema = (translations) => {
  return Yup.object().shape({
    firstName: Yup.string()
      .min('1', translations.minFieldText)
      .max('150', translations.maxFieldText)
      .required(translations.requiredFieldText)
      .test(
        'check-whitespace',
        translations.whiteSpaceFieldText,
        (value) => !isWhiteSpace(value)
      ),
    lastName: Yup.string()
      .min('1', translations.minFieldText)
      .max('150', translations.maxFieldText)
      .required(translations.requiredFieldText)
      .test(
        'check-whitespace',
        translations.whiteSpaceFieldText,
        (value) => !isWhiteSpace(value)
      ),
    email: Yup.string()
      .email(translations.validEmailFieldText)
      .required(translations.requiredFieldText),
    phone: Yup.string()
      .min('1', translations.minFieldText)
      .max('150', translations.maxFieldText)
      .matches(/^[+0-9. -]*$/, 'Phone number is not valid')
      .test(
        'check-whitespace',
        translations.whiteSpaceFieldText,
        (value) => !isWhiteSpace(value)
      )
      .required(translations.requiredFieldText),
    teamName: Yup.string()
      .min('1', translations.minFieldText)
      .max('150', translations.maxFieldText)
      .test(
        'check-whitespace',
        translations.whiteSpaceFieldText,
        (value) => !isWhiteSpace(value)
      ),
    tShirtSize: Yup.string()
      .required(requiredFieldText)
      .max('150', maxFieldText),
    address: Yup.string()
      .min('1', translations.minFieldText)
      .max('150', translations.maxFieldText)
      .test(
        'check-whitespace',
        translations.whiteSpaceFieldText,
        (value) => !isWhiteSpace(value)
      ),
    city: Yup.string()
      .min('1', translations.minFieldText)
      .max('150', translations.maxFieldText)
      .test(
        'check-whitespace',
        translations.whiteSpaceFieldText,
        (value) => !isWhiteSpace(value)
      ),
    zip: Yup.string()
      .min('1', translations.minFieldText)
      .max('150', translations.maxFieldText)
      .test(
        'check-whitespace',
        translations.whiteSpaceFieldText,
        (value) => !isWhiteSpace(value)
      ),
    about: Yup.string().max(maxSymbolCount, maxFieldText),
  });
};

export const Registration = ({ formFieldRef }) => {
  const [formIsSubmitted, setIsFormSubmitted] = useState(false);
  const [formBeingSubmitted, setFormBeingSubmitted] = useState(false);
  const [currentSymbolCount, setCurrentSymbolCount] = useState(0);
  const [hasSubmissionError, setSubmissionError] = useState(false);
  const languageContext = useContext(GlobalStateContext);
  const english = languageOptions.find((lang) => lang.id === 'en');

  languageContext.setLanguage(english);
  const translation = languageContext.dictionary.translations;

  const validationTranslations = {
    requiredFieldText: translation['form.validation.isRequired'],
    minFieldText: translation['form.validation.minFieldText'],
    maxFieldText: translation['form.validation.maxFieldText'],
    whiteSpaceFieldText: translation['form.validation.noWhitespace'],
    validEmailFieldText: translation['form.validation.validEmail'],
    atLeastOneRequired: translation['form.validation.isRequiredOneAcademy'],
  };

  const handleFieldFocus = () => {
    if (hasSubmissionError) {
      setSubmissionError(false);
    }
  };

  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
      )
      .join('&');
  };

  const handleSubmit = async (values, actions) => {
    setFormBeingSubmitted(true);
    fetch('', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': 'hackathon-from-2021',
        ...values,
      }),
    })
      .then(() => {
        actions.setSubmitting(false);
        actions.setStatus('submitted');
        setIsFormSubmitted(true);
        setFormBeingSubmitted(false);

        if (typeof window !== 'undefined') {
          window.dataLayer.push({ event: 'hackathon' });
        }
      })
      .catch(() => {
        actions.setSubmitting(false);
        setSubmissionError(true);
        setFormBeingSubmitted(false);
      });
  };

  return (
    <>
      <div
        className={classNames('hackathon-registration', {
          submitted: formIsSubmitted || formBeingSubmitted,
        })}
        id="hackathon-register-form"
      >
        <div className="hackathon-registration__form-wrapper">
          <h2 id="modal-title" className="hackathon-registration__title">
            Register
          </h2>
          <p className="hackathon-registration__desc">
            Registration will be open until November 10th.
          </p>

          <Formik
            initialValues={{
              firstName: '',
              lastName: '',
              email: '',
              phone: '',
              teamName: '',
              tShirtSize: '',
              address: '',
              city: '',
              zip: '',
              about: '',
            }}
            validationSchema={validationSchema(validationTranslations)}
            onSubmit={handleSubmit}
          >
            {() => (
              <div className="hackathon-registration__wrapper">
                <Form
                  name="hackathon-from-2021"
                  method="post"
                  data-netlify="true"
                  className="hackathon-registration__form form"
                >
                  {hasSubmissionError && (
                    <p className="hackathon-registration__error">
                      Error submitting form
                    </p>
                  )}
                  <div className="hackathon-registration__row">
                    <div className="hackathon-registration__column">
                      <FormField
                        onFocus={handleFieldFocus}
                        label="First Name"
                        name="firstName"
                        formFieldRef={formFieldRef}
                      />
                    </div>
                    <div className="hackathon-registration__column">
                      <FormField
                        onFocus={handleFieldFocus}
                        label="Last Name"
                        name="lastName"
                        formFieldRef={formFieldRef}
                      />
                    </div>
                  </div>
                  <div className="hackathon-registration__row">
                    <div className="hackathon-registration__column">
                      <FormField
                        onFocus={handleFieldFocus}
                        label="Email"
                        name="email"
                        formFieldRef={formFieldRef}
                      />
                    </div>
                    <div className="hackathon-registration__column">
                      <FormField
                        onFocus={handleFieldFocus}
                        label="Phone"
                        name="phone"
                        formFieldRef={formFieldRef}
                      />
                    </div>
                  </div>
                  <FormField
                    onFocus={handleFieldFocus}
                    label="Address"
                    name="address"
                    formFieldRef={formFieldRef}
                  />
                  <div className="hackathon-registration__row">
                    <div className="hackathon-registration__column">
                      <FormField
                        onFocus={handleFieldFocus}
                        label="City"
                        name="city"
                        formFieldRef={formFieldRef}
                      />
                    </div>
                    <div className="hackathon-registration__column">
                      <FormField
                        onFocus={handleFieldFocus}
                        label="Zip"
                        name="zip"
                        formFieldRef={formFieldRef}
                      />
                    </div>
                  </div>
                  <div className="hackathon-registration__row">
                    <div className="hackathon-registration__column stretched">
                      <div className="hackathon-registration__helper-text">
                        This is a team based hackathon event. Teams should be of
                        up to 4 members
                        <br />
                        (Leave empty if you are looking to join a team)
                      </div>
                      <FormField
                        onFocus={handleFieldFocus}
                        label="Team Name"
                        name="teamName"
                        formFieldRef={formFieldRef}
                      />
                    </div>
                    <div className="hackathon-registration__column stretched">
                      <div className="hackathon-registration__helper-text">
                        Typical unisex t-shirts
                      </div>
                      <FormSelect
                        label="T-Shirt Size"
                        onFocus={handleFieldFocus}
                        name="tShirtSize"
                        options={['S', 'M', 'L', 'XL']}
                      />
                    </div>
                  </div>
                  <div className="hackathon-registration__helper-text">
                    We would like to know more about you, your test automation
                    skillset and why you decided to participate. This is
                    optional field: The information will allow for better team
                    compositions for participants that come without one. Also
                    the information will help us make future events better.
                  </div>
                  <FormTextArea
                    maxSymbolCount={maxSymbolCount}
                    setCurrentSymbolCount={setCurrentSymbolCount}
                    onFocus={handleFieldFocus}
                    label="Tell us more about you"
                    name="about"
                  />
                  <div className="form__counter">
                    {`${currentSymbolCount} / ${maxSymbolCount}`}
                  </div>
                  <SubmitButton>Register</SubmitButton>
                </Form>
                {formBeingSubmitted && (
                  <div className="hackathon-registration__loading">
                    <LoadingIndicator />
                  </div>
                )}
                {formIsSubmitted && (
                  <div className="hackathon-registration__message-wrapper">
                    <SubmitMessage>
                      <figure className="hackathon-registration__message-icon-wrapper">
                        <img
                          src={ConfirmationIcon}
                          alt=""
                          className="hackathon-registration__message-icon"
                        />
                      </figure>
                      <h3 className="hackathon-registration__message-title">
                        Nicely done!
                      </h3>
                      <p className="hackathon-registration__message-description">
                        Registration successful.
                        <br />
                        We will contact you with more information.
                      </p>
                    </SubmitMessage>
                  </div>
                )}
              </div>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

Registration.propTypes = {
  formFieldRef: PropTypes.shape(),
};

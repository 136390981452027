import React from 'react';

const NoRegistration = () => {
  return (
    <div className="hackathon-registration hackathon-registration--end">
      <div className="hackathon-registration__form-wrapper">
        <h2 id="modal-title" className="hackathon-registration__title">
          Registration has ended
        </h2>
        <p className="faq__other-questions">
          Do you have questions?&nbsp;
          <a href="mailto:justas.lauzadis@devbridge.com">Send us an email</a>.
        </p>
      </div>
    </div>
  );
};

export { NoRegistration };

import React, { useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import './scss/styles.scss';
import { GlobalStateContext } from 'containers/GlobalContextProvider';
import { languageOptions } from 'lang';
import { Layout } from 'layout';
import { Faq } from 'components/faq';
import { handleScrollToForm } from 'utilities/handleScrollToForm';
import {
  Header,
  HeroBanner,
  About,
  Agenda,
  Gallery,
  NoRegistration,
} from './components';

const AutomationHackathon = ({ data }) => {
  const { markdownRemark } = data;
  const formFieldRef = useRef(null);
  const languageContext = useContext(GlobalStateContext);
  languageContext.setLanguage(languageOptions.find((lang) => lang.id === 'en'));

  return (
    <Layout
      metaTitle={
        markdownRemark.frontmatter.metaInformation.metaTitle ||
        markdownRemark.frontmatter.title
      }
      metaDescription={
        markdownRemark.frontmatter.metaInformation.metaDescription || null
      }
      metaKeywords={
        markdownRemark.frontmatter.metaInformation.metaKeywords || null
      }
      metaImage={
        markdownRemark.frontmatter.metaInformation.metaImage
          ? markdownRemark.frontmatter.metaInformation.metaImage.children[0]
              .fixed.src
          : null
      }
      hasHeader={false}
      className="hackathon"
    >
      <div>
        <Header />
        <HeroBanner
          title={markdownRemark.frontmatter.title}
          subTitle={markdownRemark.frontmatter.subTitle}
          date={markdownRemark.frontmatter.date}
          location={markdownRemark.frontmatter.location}
          ctaButtonText={markdownRemark.frontmatter.ctaButtonText}
          handleScrollToForm={() =>
            handleScrollToForm(formFieldRef, '#hackathon-register-form')
          }
        />
        <About description={markdownRemark.frontmatter.about.description} />
        <Agenda
          title={markdownRemark.frontmatter.agenda.title}
          morningEvents={markdownRemark.frontmatter.agenda.morningEvents}
          afternoonEvents={markdownRemark.frontmatter.agenda.afternoonEvents}
        />
        <Faq
          title={markdownRemark.frontmatter.faqSection.title}
          otherQuestions={markdownRemark.frontmatter.faqSection.otherQuestions}
          questions={markdownRemark.frontmatter.faqSection.questions}
          questionsEmail={markdownRemark.frontmatter.faqSection.questionsEmail}
        />
        <Gallery images={markdownRemark.frontmatter.gallery} />
        <NoRegistration />
      </div>
    </Layout>
  );
};

AutomationHackathon.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape(),
  }),
};

export const automationHackathon2020 = graphql`
  query automationHackathon($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        metaInformation {
          metaTitle
          metaDescription
          metaKeywords
          metaImage {
            children {
              ... on ImageSharp {
                fixed(width: 1080, height: 1080) {
                  src
                }
              }
            }
          }
        }
        title
        subTitle
        date
        location
        ctaButtonText
        about {
          description
        }
        agenda {
          title
          morningEvents {
            time
            event
          }
          afternoonEvents {
            time
            event
          }
        }
        safety {
          title
          safetyList {
            item
          }
          note
        }
        faqSection {
          title
          otherQuestions
          questionsEmail
          questions {
            questionText
            answer
          }
        }
        gallery {
          children {
            ... on ImageSharp {
              fluid(maxWidth: 900, maxHeight: 900) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;

export default AutomationHackathon;

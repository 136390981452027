import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import IconBag from 'images/icon-bag.svg';
import IconChallenge from 'images/icon-challenge.svg';
import IconPrize from 'images/icon-prize.svg';
import IconUnion from 'images/icon-union.svg';

const Perk = ({ perk, text, image }) => {
  return (
    <span className="hackathon-about__perk-wrapper">
      <div
        className={classNames(
          'hackathon-about__perk',
          `hackathon-about__perk--${perk}`
        )}
      >
        <div className="hackathon-about__perk-content">
          <img src={image} alt={text} className="hackathon-about__perk-icon" />
          <p
            className="hackathon-about__perk-text"
            dangerouslySetInnerHTML={{ __html: text }}
          />
        </div>
      </div>
    </span>
  );
};

Perk.propTypes = {
  perk: PropTypes.string,
  text: PropTypes.string,
  image: PropTypes.string,
};

const About = ({ description }) => (
  <div className="hackathon-about">
    <div className="hackathon-about__content">
      <div
        className="hackathon-about__text"
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </div>
    <figure className="hackathon-about__perks">
      <Perk perk="full-day" text="Full-day <br />Hacking" image={IconUnion} />
      <Perk
        perk="challenges"
        text="Ultimate <br />Challenges"
        image={IconChallenge}
      />
      <Perk perk="prizes" text="Amazing <br />Prizes" image={IconPrize} />
      <Perk perk="swag" text="Free <br />Swag" image={IconBag} />
    </figure>
  </div>
);

About.propTypes = {
  description: PropTypes.string.isRequired,
};

export { About };

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const FaqCard = ({
  question,
  answer,
  index,
  handleKeyDown,
  setCurrentQuestionIndex,
}) => {
  const [questionOpen, setQuestionOpen] = useState(false);
  const toggleQuestion = () => setQuestionOpen(!questionOpen);

  const handleFocus = () => {
    setCurrentQuestionIndex(index);
  };

  return (
    <div className={classNames('faq-card', questionOpen && 'open')}>
      <button
        type="button"
        className="faq-card__button"
        onClick={toggleQuestion}
        aria-expanded={questionOpen}
        aria-controls={`section${index}`}
        id={`accordion${index}`}
        onKeyDown={handleKeyDown}
        onFocus={handleFocus}
      >
        <span className="faq-card__question">{question}</span>
        <span className="faq-card__icon" />
      </button>
      {answer && answer !== '' && (
        <div
          className="faq-card__answer-wrapper"
          id={`section${index}`}
          role="region"
          aria-labelledby={`accordion${index}`}
        >
          <div
            className="faq-card__answer"
            dangerouslySetInnerHTML={{ __html: answer }}
          />
        </div>
      )}
    </div>
  );
};

FaqCard.propTypes = {
  index: PropTypes.number.isRequired,
  handleKeyDown: PropTypes.func.isRequired,
  setCurrentQuestionIndex: PropTypes.func.isRequired,
  question: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
};

export { FaqCard };

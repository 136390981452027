import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';

export const Gallery = ({ images }) => {
  return (
    <div className="hackathon-gallery">
      <div className="hackathon-gallery__frame">
        <div className="hackathon-gallery__row">
          {images &&
            images.map((image, index) => (
              <div className="hackathon-gallery__column">
                <Img
                  className={`hackathon-gallery__img hackathon-gallery__img--${index}`}
                  fluid={image.children[0].fluid}
                  alt=""
                />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

Gallery.propTypes = {
  images: PropTypes.oneOfType([PropTypes.array]),
};
